.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    border: black solid 1px;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.792);
}

.username {
    color: red;
    font-size: 125%;
}

.usernameContainer {
    display: flex;
    flex-direction: row;
    width: 40%;
    padding-left: 5%;
}

.followContainer {
    display: flex;
    flex-direction: row;
    width: 50%;
    padding-left: 5%;
}

.statContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 60%;
}

.likeContainer {
    display: flex;
    flex-direction: row;
    width: 50%;
    padding-left: 5%;
}