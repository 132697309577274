.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  url('https://diapogram.com/upload/2021/11/04/20211104134353-a36a64c3.jpg');
  background-size: cover;
  background-position: center;
}

.containerTikTokStats {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 80%;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.716);
  padding: 1%;
  gap: 3%;
  border-radius: 20px;
  border: black solid 1px;
}
